import React from 'react';

import Amplify, { API, graphqlOperation, DataStore, Predicates }  from "aws-amplify";

// import models
import { 
  Post, 
  // Users
} from '../models';

import awsConfig from "../aws-exports";
// console.log(awsConfig);


import _ from 'lodash';
import moment from 'moment-strftime';
// import {graphql} from 'gatsby';

import { Layout } from '../components/index';
import { classNames, htmlToReact, getPages, Link, withPrefix } from '../utils';
Amplify.configure(awsConfig); 

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
// export const query = graphql`
//   query($url: String) {
//     sitePage(path: {eq: $url}) {
//       id
//     }
//   }
// `;
export const ListPosts = `
query MyQuery {
  listPosts {
    items {
      id
      author
      createdAt
      description
      published
      text
      title
      updatedAt
      date
      image
      excerpt
      image_alt
      permalink
      thumb_image
      thumb_image_alt
      title_seo
    }
  }
}

`;

export default class Blog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: "My button",
        dataFromDBPosts: [],
        posts: []
      }
    }

    onQuery = async () => {
      console.log("->onQuery");
      // const posts = await DataStore.query(Post);
      // console.log("QUERY_POSTS_RESULT", posts);
      const posts = await API.graphql({ query: ListPosts });
      console.log('newListPosts', posts.data.listPosts.items);
      
      // const comments = await DataStore.query(Comment);
      this.setState({ posts: posts.data.listPosts.items });
      // console.log("QUERY_COMMENTS_RESULT", comments);
    };
    

    componentWillMount = async () => {
      await this.onQuery();
      console.log('!!!!!!!!!!!!!!!!!!');
      // let temper = await DataStore.query(Users)
      // console.log("temper", temper);

      // temper = await DataStore.query(Post)
      // console.log("temper", temper);
      
    }
    render() {
        // console.log("query", query);
        const createPost = () => {
            console.log("createPot running"); 
        }
        // console.log("models", models());
        let dynamicPosts = this.state.posts
        dynamicPosts.map( (item, idx) => {
          let newPage= {
            
            url: (item.permalink == undefined || item.permalink == "") ? `/blog/post-${item.id}/`: `/blog/${item.permalink}/`,
            relativePath: `/blog/[post].js/`,
            relativeDir: 'blog',
            base: '',
            name: item.permalink,
            frontmatter: {
                title: item.title,
                hide_title: false,
                template: 'post',
                subtitle: item.title,
                excerpt: item.excerpt,
                date: '2021-05-12',
                thumb_image: item.thumb_image,
                thumb_image_alt: item.thumb_image_alt,
                image: item.image,
                image_alt: item.image_alt,
                seo: {
                  title: item.title,
                  descriptiom: "",
                  extrs: [
                    {
                      keyName: "property",
                      name: "og:type",
                      value: "article",
                    }
                  ]
                }
            },
            html: item.text
          } 
          // console.log("this.state idx", idx)
          // console.log('this.state item', item);
          // display_posts.push(newPage)
          let fury = this.props.pageContext.pages
          // console.log('fury', fury);
          let findItems = _.find(fury, {name: item.permalink})
          if (findItems == undefined) {
            // console.log("findItems", findItems)
            fury.push(newPage);
          } else {
            // console.log("findItems", findItems)
          }
          
        })
        let display_posts = _.orderBy(getPages(this.props.pageContext.pages, '/blog'), 'frontmatter.date', 'desc');
          
        console.log("this.props", this.props);
        console.log("new ====>[0] ", display_posts[0]);
        // console.log("this.state.isLoggedIn", this.state);
        return (
            <Layout {...this.props}>
            <div className="inner outer">
              <header className={classNames('page-header', 'inner-sm', {'screen-reader-text': _.get(this.props, 'pageContext.frontmatter.hide_title', null)})}>
                <h1 className="page-title line-top">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                <div className="page-subtitle">
                  {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle', null))}
                </div>
                )}
              </header> 
              <div className={classNames('post-feed', 'grid', {'grid-col-2': _.get(this.props, 'pageContext.frontmatter.col_number', null) === 'two', 'grid-col-3': _.get(this.props, 'pageContext.frontmatter.col_number', null) === 'three'})}>
                {_.map(display_posts, (post, post_idx) => (
                <article key={post_idx} className="post grid-item">
                  <div className="post-inside">
                    {_.get(post, 'frontmatter.thumb_image', null) && (
                    <Link className="post-thumbnail" to={withPrefix(_.get(post, 'url', null))}>
                      <img src={withPrefix(_.get(post, 'frontmatter.thumb_image', null))} alt={_.get(post, 'frontmatter.thumb_image_alt', null)} />
                    </Link> 
                    )}
                    <header className="post-header">
                      <h2 className="post-title"><Link to={withPrefix(_.get(post, 'url', null))} rel="bookmark">{_.get(post, 'frontmatter.title', null)}</Link></h2>
                      <div className="post-meta">
                        <time className="published"
                          dateTime={moment(_.get(post, 'frontmatter.date', null)).strftime('%Y-%m-%d %H:%M')}>{moment(_.get(post, 'frontmatter.date', null)).strftime('%B %d, %Y')}</time>
                      </div>
                    </header>
                    {_.get(post, 'frontmatter.excerpt', null) && (
                    <p className="post-content">
                      {_.get(post, 'frontmatter.excerpt', null)}
                    </p>
                    )}
                  </div>
                  
                  
                </article>
                ))}
              </div>
            </div>
            </Layout>
        );
    }
}
